<template>
  <v-card>
    <v-row
      class="px-2 ma-0"
      align="center"
    >
      <v-col
        cols="6"
      >
        <v-select
          v-model="product_id"
          :items="productIds"
          placeholder="ペレットID"
          outlined
          clearable
          dense
          hide-details
          class="subtitle-1"
        ></v-select>
      </v-col>
    </v-row>
    <v-card-text class="pb-0">
      <v-virtual-scroll
        :bench="0"
        :items="f_MthlyProdStock"
        :height="vScrollHeight"
        :item-height="50"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item
            :key="index"
            class="d-flex align-center px-2"
          >
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-semibold text-base"
                v-text="item.product_id"
              ></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <div class="font-weight-semibold text-base text-right pr-3">
                <label :style="item.sum_total < 0 ? 'color:#F44336' : ''">{{ item.sum_total ? item.sum_total.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil, mdiDelete, mdiExportVariant } from '@mdi/js'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data: () => ({
    icon: { mdiPencil, mdiDelete, mdiExportVariant },
    productIds: [],
    product_id: '',
  }),

  computed: {
    ...mapState('app', ['clientHeight']),
    ...mapState('monthlyStockStore', ['MthlyProdStock', 'f_MthlyProdStock']),
    vScrollHeight() {
      return `${this.clientHeight - 162}px`
    },
  },

  watch: {
    product_id() {
      this.filterMthlyProdStock(this.product_id)
    },
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapActions('monthlyStockStore', ['loadMthlyProdStock']),
    ...mapMutations('monthlyStockStore', ['filterMthlyProdStock']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData(selMonth) {
      this.loadMthlyProdStock(selMonth).then(() => {
        this.filterMthlyProdStock(this.product_id)
        this.productIds = [...new Set(this.MthlyProdStock.map(fs => fs.product_id))]
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
